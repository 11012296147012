export default {
    namespaced: true,
    state: {
        currentTimestamp: Number((new Date().getTime() / 1000).toFixed(0)),
        currentTimestampInterval: null
    },
    getters: {
        getCurrentTimestamp: state => {
            return state.currentTimestamp
        }
    },
    mutations: {
        START_INTERVAL(state) {
            state.currentTimestampInterval = setInterval(() => {
                state.currentTimestamp = Number((new Date().getTime() / 1000).toFixed(0))
            }, 500)
        },
        END_INTERVAL(state) {
            if (state.currentTimestampInterval) {
                clearInterval(state.currentTimestampInterval)
                state.currentTimestamp = 0
            }
        }
    },
    actions: {
        start_timestamp_interval({ commit }) {
            commit('START_INTERVAL')
        },
        end_timestamp_interval({ commit }) {
            commit('END_INTERVAL')
        }
    }
}