import i18n from '@/i18n/i18n'

export const Locales = {
    Slovenian:'sl',
    Croatian:'hr',
    English:'en',
    Arabic: 'ar'
}
export const Countries = {
    Slovenia:1,
    Croatia:2,
    Syria:3,
    Lebanon:4,
    SierraLeone:5
}

import sl from '@/assets/images/flags/sl.png'
import en from '@/assets/images/flags/en.png'
import hr from '@/assets/images/flags/hr.png'
import ar from '@/assets/images/flags/ar.png'

export const getLocaleObjects = () => {
    return [
        {
            id: Locales.Slovenian,
            name: i18n.t('menu.locales.slovenian'),
            img: sl,
            label: i18n.t('menu.locales.slovenian')
        },
        {id: Locales.Croatian, name: i18n.t('menu.locales.croatian'), img: hr, label: i18n.t('menu.locales.croatian')},
        {id: Locales.English, name: i18n.t('menu.locales.english'), img: en, label: i18n.t('menu.locales.english')}
    ]
}

export const getLocaleObjectsCashRegister = () => {
    return [
        {
            id: Locales.Slovenian,
            name: i18n.t('menu.locales.slovenian'),
            img: sl,
            label: i18n.t('menu.locales.slovenian')
        },
        {id: Locales.Croatian, name: i18n.t('menu.locales.croatian'), img: hr, label: i18n.t('menu.locales.croatian')},
        {id: Locales.English, name: i18n.t('menu.locales.english'), img: en, label: i18n.t('menu.locales.english')},
        {id: Locales.Arabic, name: i18n.t('menu.locales.arabic'), img: ar, label: i18n.t('menu.locales.arabic')}
    ]
}

