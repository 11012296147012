import * as Sentry from '@sentry/vue'
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        messages: [],
        unreadUrgentMessages: [],
        unreadMessagesCount: 0,
        unreadMessagesCountAllClients: 0,
        unreadClients: [],
        unreadMessages: [],
        last5: []
    },
    getters: {
        getLast5Messages: state => {
            return state.messages
        },
        getUnreadUrgentMessages: state => {
            return state.unreadUrgentMessages
        },
        getUnreadMessages: state => {
            return state.unreadMessages
        },
        getUnreadMessagesCount: state => {
            return state.unreadMessagesCount
        },
        getUnreadMessagesCountAllClients: state => {
            return state.unreadMessagesCountAllClients
        },
        getUnreadClients: state => {
            return state.unreadClients
        }
    },
    mutations: {
        SET_LAST_FIVE_MESSAGES(state, payload) {
            state.messages = payload
        },
        SET_UNREAD_MESSAGES(state, payload) {
            state.unreadMessages = []
            state.unreadMessages = payload
        },
        SET_UNREAD_URGENT_MESSAGES(state, payload) {
            state.unreadUrgentMessages = payload
        },
        SET_UNREAD_MESSAGES_COUNT(state, payload) {
            state.unreadMessagesCount = payload
        },
        SET_UNREAD_MESSAGES_COUNT_FOR_ALL_CLIENTS(state, payload) {
            state.unreadMessagesCountAllClients = payload
        },
        SET_UNREAD_CLIENTS(state, payload) {
            state.unreadClients = payload
        }
      },
    actions: {
        async set_messages({ rootState, commit }) {
            try {
                if (!rootState.user.loggedIn || !rootState.user.userHasClients) {
                    return
                }

                const res1 = Vue.prototype.$http.get('/api/client/v1/messages/last_five/reduced/')
                const res2 = Vue.prototype.$http.get('/api/client/v1/messages/all/reduced/')

                const responses = await Promise.all([res1, res2])

                commit('SET_LAST_FIVE_MESSAGES', responses[0].data.messages)
                commit('SET_UNREAD_MESSAGES_COUNT', responses[0].data.unread_count)
                commit('SET_UNREAD_MESSAGES_COUNT_FOR_ALL_CLIENTS', responses[0].data.unread_count_all_clients)
                commit('SET_UNREAD_CLIENTS', responses[0].data.unread_clients)
                commit('SET_UNREAD_MESSAGES', responses[1].data.messages.filter(x => x.read === false) ?? [])

            } catch (err) {
                Sentry.captureException(err)
                commit('SET_UNREAD_MESSAGES', [])
                commit('SET_UNREAD_MESSAGES_COUNT', 0)
            }
        },
        async set_unread_urgent_messages({rootState, commit}) {
            try {

                if (!rootState.user.loggedIn || !rootState.user.userHasClients) {
                    return
                }
                
                const response = await Vue.prototype.$http.get('/api/client/v1/messages/unread/urgent/')
                commit('SET_UNREAD_URGENT_MESSAGES', response.data.messages)
            } catch (err) {
                Sentry.captureException(err)
                commit('SET_UNREAD_URGENT_MESSAGES', [])
            }
        }
    }
}
