import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        setupProgress: null,
        setupProgressRequired: null,
        setupProgressFull: null,
        clickedItem: '',
        redirect: true,
        firstLoad : false
    },
    getters: {
        setupProgress: state => {
            return state.setupProgress
        },
        clickedItem: state => {
            return state.clickedItem
        },
        redirect: state => {
            return state.redirect
        },
        firstLoad: state => {
            return state.firstLoad
        }
    },
    mutations: {
        SET_SETUP_PROGRESS(state, status) {
            state.setupProgress = status
        },
        SET_CLICKED_ITEM(state, item) {
            state.clickedItem = item
        },
        SET_REDIRECT(state, data) {
            state.redirect = data
        }
    },
    actions: {
        async getSetupProgress({commit}) {
            const response = await Vue.prototype.$http.get('/api/client/v1/installation_wizard/progress')
                const progress = response.data
                localStorage.setItem('progress', progress)
                commit('SET_SETUP_PROGRESS', progress)
        },
        setClickedItem({commit}, data) {
            commit('SET_CLICKED_ITEM', data)
        },
        setRedirect({commit}, data) {
            commit('SET_REDIRECT', data)
        }
    }  

}
