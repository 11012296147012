
export default {
    namespaced: true,
    state: {
       clientData: null
    },
    getters: {
        clientData: state => {
            return state.clientData
        }
    },
    mutations: {
        SET_CLIENT_DATA(state, data) {
            state.clientData = data
        }
    },
    actions: {
        setClientData({ commit }, payload) {
            commit('SET_CLIENT_DATA', payload)
        }
    }
}
